import React, { useEffect, useState } from 'react';
import AceEditor from 'react-ace';
import NotFound from '../assets/images/404NotFound.png';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/theme-monokai';
import { useNavigate, useParams } from 'react-router-dom';
import {
  fetchTemplateById,
  sendSampleEmail,
  updateTemplate,
} from '../network/Template';
import { fetchUserDetails } from '../network/Profile';
import { toast } from 'react-toastify';
import '../assets/Styles/EditTemplate.css';
import { CloseOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, Skeleton } from 'antd';
import ProfileHeader from '../components/Layout/ProfileHeader';

const EditTemplate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [template, setTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [buttonState, setButtonState] = useState({
    text: 'Send Sample Mail',
    loading: false,
    sent: false,
  });

  useEffect(() => {
    const loadTemplate = async () => {
      setIsLoading(true);
      try {
        const fetchedTemplate = await fetchTemplateById(id);
        setTemplate(fetchedTemplate);
      } catch (error) {
        toast.error('Failed to fetch template.');
      } finally {
        setIsLoading(false);
      }
    };
    loadTemplate();
  }, [id]);

  const handleCancel = () => {
    navigate('/template');
  };

  const handleSave = async () => {
    if (
      !template.templateType ||
      !template.templateFormat ||
      !template.templateData
    ) {
      toast.error('All fields are required.');
      return;
    }
    setIsSaving(true);
    try {
      await updateTemplate(template);
      toast.success('Template updated successfully.');
    } catch (error) {
      toast.error('Failed to update the template. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleSampleEmail = async () => {
    setButtonState({ text: 'Sending', loading: true, sent: false });
    try {
      const testTemplate = {
        templateType: template.templateType,
        templateFormat: template.templateFormat,
        templateData: template.templateData,
        subject: 'Test Email',
      };
      if (template) {
        const userDetails = await fetchUserDetails();
        await sendSampleEmail(testTemplate, userDetails.email);
        toast.success('Mail sent to your registered email');
        setButtonState({ text: 'Sent', loading: false, sent: true });
      } else {
        toast.error('No Templates Generated!');
        setButtonState({
          text: 'Send Sample Mail',
          loading: false,
          sent: false,
        });
      }
    } catch (error) {
      toast.error(`Failed to send sample email: ${error.message}`);
      setButtonState({ text: 'Send Sample Mail', loading: false, sent: false });
    }
  };

  const handleChange = (field, value) => {
    setTemplate({ ...template, [field]: value });
  };

  const renderPreview = (format, content) => {
    if (!content) return <p>No template data available.</p>;

    if (format.toLowerCase() === 'html') {
      return content;
    } else if (format.toLowerCase() === 'text') {
      return <pre className="text-preview">{content}</pre>;
    } else {
      return <p>Unsupported format.</p>;
    }
  };

  if (!template && !isLoading) {
    return (
      <div className="flex flex-col justify-center items-center min-h-screen">
        <img className="h-28" src={NotFound} alt={'Page Not Found'} />
        <p className="font-bold text-xl mulish-normal mt-10">
          Template not found.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white p-0 lg:p-2 rounded-lg w-full flex flex-col">
      <ProfileHeader />
      {isLoading ? (
        <>
          <div className="flex justify-between p-3">
            <Skeleton.Input loading={isLoading} className="" active />
            <div className="flex items-center justify-center">
              <Skeleton.Input loading={isLoading} className="w-10" active />
            </div>
          </div>
          <div className="flex lg:flex-row flex-col">
            <Skeleton
              loading={isLoading}
              className="p-3 lg:w-3/4 w-full flex flex-col items-center justify-center"
              active
              paragraph={{ rows: 12 }}
            />
            <Skeleton
              loading={isLoading}
              className="p-3 lg:flex hidden w-1/4 flex-col items-center justify-center"
              active
              paragraph={{ rows: 12 }}
            />
          </div>
          <Skeleton.Input loading={isLoading} className="p-3" active />
        </>
      ) : (
        <Form layout="vertical">
          {/* Skeleton for Title Section */}

          <div className="edit-title lg:px-4 px-2 mb-0 h-min flex items-center justify-between rounded-lg mt-2">
            <Form.Item className="template-title mr-2">
              <Input
                value={template.templateType}
                onChange={(e) => handleChange('templateType', e.target.value)}
                placeholder="Enter Title"
              />
            </Form.Item>
            <div className="flex gap-2">
              <Form.Item className="flex form-button items-center justify-center">
                <Button
                  type="primary"
                  icon={<SendOutlined />}
                  className="bg-black"
                  onClick={handleSampleEmail}
                  loading={buttonState.loading}
                >
                  {buttonState.text}
                </Button>
              </Form.Item>
              <Form.Item className="form-button items-center justify-center">
                <button
                  onClick={handleCancel}
                  className="w-full px-2 py-1 rounded-lg text-black bg-gray-100 hover:bg-gray-200"
                >
                  {<CloseOutlined />}
                </button>
              </Form.Item>
            </div>
          </div>

          <Form.Item required>
            {template.templateFormat.toLowerCase() === 'html' ? (
              <div className="flex w-full px-2 pb-2 pt-0 justify-content-between lg:flex-row flex-col rounded-lg relative">
                <div className="responsive-editor-container bg-gray-5">
                  <AceEditor
                    mode="html"
                    theme="monokai"
                    id="htmlEditor"
                    name="htmlEditor"
                    onChange={(newValue) =>
                      handleChange('templateData', newValue)
                    }
                    value={template.templateData}
                    editorProps={{ $blockScrolling: false }}
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: false,
                      showLineNumbers: false,
                      tabSize: 1,
                      fontSize: '14px',
                      lineHeight: 4,
                      fixedWidthGutter: true,
                    }}
                    style={{
                      height: '92vh',
                      width: '700px',
                      border: '1px solid #ccc',
                      borderRadius: '0.5rem',
                    }}
                  />
                </div>
                <div className="preview md:p-4 p-2 md:pt-0 pt-2 h-[92vh] overflow-scroll overflow-y-scroll overflow-x-hidden">
                  <div
                    className="preview-container overflow-y-hidden"
                    dangerouslySetInnerHTML={{
                      __html: renderPreview(
                        template.templateFormat,
                        template.templateData,
                      ),
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className="md:px-6 px-2 py-2">
                <Input.TextArea
                  value={template.templateData}
                  onChange={(e) => handleChange('templateData', e.target.value)}
                  rows={15}
                  placeholder="Enter template content..."
                  className="edit-text-template"
                />
              </div>
            )}
          </Form.Item>

          {/* Skeleton for Save Button */}
          <Form.Item className="flex w-full mb-4 form-button items-center justify-center">
            <Button
              type="primary"
              onClick={handleSave}
              loading={isSaving}
              className="w-full bg-black mb-4 hover:bg-blue-600"
            >
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default EditTemplate;
