import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Avatar, Dropdown, Layout, Menu } from 'antd';
import {
  FileOutlined,
  HistoryOutlined,
  MailOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import FrostMailLogo from '../../assets/images/NamedLogo.png';
import '../../assets/Styles/navbar.css';
import { useDispatch } from 'react-redux';
import { logoutAction } from '../../redux/auth/authActions';
import { useNavigate } from 'react-router-dom';
import { fetchUserDetails } from '../../network/Profile';
import LogoutButton from '../Logout';

const { Header } = Layout;

const ProfileHeader = () => {
  const [user, setUser] = useState(null);
  const [isGmailAccount, setIsGmailAccount] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        const fetchedUser = await fetchUserDetails();

        setUser(fetchedUser);
        if (fetchedUser && !fetchedUser.email.endsWith('@gmail.com')) {
          setIsGmailAccount(false);
          handleAutomaticLogout();
        }
      } catch (error) {
        console.error('Failed to fetch user details:', error);
      }
    };

    getUserDetails();
  }, []);

  const handleAutomaticLogout = () => {
    dispatch(logoutAction());
    navigate('/login');
    toast.error('Only Gmail accounts are allowed. Logging out...');
  };

  const profileMenu = (
    <Menu>
      <Menu.Item key="1" icon={<FileOutlined />}>
        <Link to="/template">My Templates</Link>
      </Menu.Item>
      <Menu.Item key="2" icon={<MailOutlined />}>
        <Link to="/send">Send Mail</Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<HistoryOutlined />}>
        <Link to="/history">History</Link>
      </Menu.Item>
      <div className="self-center w-full">
        {isGmailAccount ? (
          <LogoutButton />
        ) : (
          <p>Only Gmail Accounts are allowed</p>
        )}
      </div>
    </Menu>
  );

  return (
    <Header className="navbar lg:px-4 px-3 py-2">
      <div className="logo">
        <Link to="/">
          <img className="h-10" src={FrostMailLogo} alt="FrostMailLogo" />{' '}
        </Link>
      </div>
      {user && (
        <p className="text-sm text-black lg:flex hidden font-semibold bg-gray-50 px-3 p-2 rounded-lg mr-2">
          You have sent {user?.emailsSent} out of 50 mails today.
        </p>
      )}

      <div className="profile-avatar">
        {user && (
          <p className="text-sm lg:flex hidden text-gray-600 mr-2">
            {user?.email}
          </p>
        )}

        <Dropdown overlay={profileMenu} placement="bottomRight">
          <Avatar icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
        </Dropdown>
      </div>
    </Header>
  );
};

export default ProfileHeader;
