import axiosInstance from './axiosConfig';
import { getErrorMessage } from '../utils/errorHandler';

export const fetchUserEmail = async () => {
  try {
    const response = await axiosInstance.get('/profile/email', {});
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};

export const fetchUserDetails = async () => {
  try {
    const response = await axiosInstance.get(`/profile`);
    return response.data.data;
  } catch (error) {
    throw new Error(getErrorMessage(error));
  }
};
